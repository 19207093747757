<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <b>Statistik Pencarian Lanjut</b>
          <!-- <span class="pull-right">#5669626</span> -->
        </h3>
        <hr>

        <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <gtTabs>
            <gtTab title="Tujuan Surat">
              
              <div class="col-md-6 pull-left">
                
              </div>

              <div class="col-md-12 justify-content-md-center">
                <div>
                  <Table
                    :items="items"
                    :filter="filters"
                    :header="headers_tujuansurat"
                  />
                </div>
              </div>

            </gtTab>
            <gtTab title="Nomor Surat">
              <div class="col-md-6 pull-left"></div>
              <div class="col-md-12 justify-content-md-center">
                <div>
                  <Table
                    :items="items"
                    :filter="filters"
                    :header="headers_nomorsurat"
                  />
                </div>
              </div>
            </gtTab>
            <gtTab title="Jenis Surat">
              <div class="col-md-6 pull-left"></div>
              <div class="col-md-12 justify-content-md-center">
                <div>
                  <Table
                    :items="items"
                    :filter="filters"
                    :header="headers_jenissurat"
                  />
                </div>
              </div>
            </gtTab>
            <gtTab title="Sifat Surat">
              <div class="col-md-6 pull-left"></div>
              <div class="col-md-12 justify-content-md-center">
                <div>
                  <Table
                    :items="items"
                    :filter="filters"
                    :header="headers_sifatsurat"
                  />
                </div>
              </div>
            </gtTab>
            <gtTab title="Perihal">
              <div class="col-md-6 pull-left"></div>
              <div class="col-md-12 justify-content-md-center">
                <div>
                  <Table
                    :items="items"
                    :filter="filters"
                    :header="headers_perihal"
                  />
                </div>
              </div>
            </gtTab>
            <gtTab title="Tanggal Distribusi">
              <div class="col-md-6 pull-left"></div>
              <div class="col-md-12 justify-content-md-center">
                <div>
                  <Table
                    :items="items"
                    :filter="filters"
                    :header="headers_tanggaldistribusi"
                  />
                </div>
              </div>
            </gtTab>
          </gtTabs>
        </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";
import { gtTab, gtTabs } from "@/components/gtTabs";

export default {
  components: {
    RotateSquare5,
    Table,
    gtTab,
    gtTabs
  },
  data() {
    return {
      headers_tujuansurat: {
        from: {
          label: "Tujuan Surat",
          sortable: false
        },
        document_number: {
          label: "Nomor Surat",
          sortable: false
        },
        jenis_surat: {
          label: "Jenis Surat",
          sortable: false
        },
        sifat: {
          label: "Sifat Surat",
          sortable: false
        },
        tanggal_diterima: {
          label: "Tanggal Distribusi",
          sortable: false
        },
        subject: {
          label: "Perihal",
          sortable: false
        },
      },

      headers_nomorsurat: {
        from: {
          label: "Tujuan Surat",
          sortable: false
        },
        document_number: {
          label: "Nomor Surat",
          sortable: false
        },
        jenis_surat: {
          label: "Jenis Surat",
          sortable: false
        },
        sifat: {
          label: "Sifat Surat",
          sortable: false
        },
        tanggal_diterima: {
          label: "Tanggal Distribusi",
          sortable: false
        },
        subject: {
          label: "Perihal",
          sortable: false
        },
      },

      headers_jenissurat: {
        from: {
          label: "Tujuan Surat",
          sortable: false
        },
        document_number: {
          label: "Nomor Surat",
          sortable: false
        },
        jenis_surat: {
          label: "Jenis Surat",
          sortable: false
        },
        sifat: {
          label: "Sifat Surat",
          sortable: false
        },
        tanggal_diterima: {
          label: "Tanggal Distribusi",
          sortable: false
        },
        subject: {
          label: "Perihal",
          sortable: false
        },
      },

      headers_sifatsurat: {
        from: {
          label: "Tujuan Surat",
          sortable: false
        },
        document_number: {
          label: "Nomor Surat",
          sortable: false
        },
        jenis_surat: {
          label: "Jenis Surat",
          sortable: false
        },
        sifat: {
          label: "Sifat Surat",
          sortable: false
        },
        tanggal_diterima: {
          label: "Tanggal Distribusi",
          sortable: false
        },
        subject: {
          label: "Perihal",
          sortable: false
        },
      },

      headers_perihal : {
        from: {
          label: "Tujuan Surat",
          sortable: false
        },
        document_number: {
          label: "Nomor Surat",
          sortable: false
        },
        jenis_surat: {
          label: "Jenis Surat",
          sortable: false
        },
        sifat: {
          label: "Sifat Surat",
          sortable: false
        },
        tanggal_diterima: {
          label: "Tanggal Distribusi",
          sortable: false
        },
        subject: {
          label: "Perihal",
          sortable: false
        },
      },

      headers_tanggaldistribusi: {
        from: {
          label: "Tujuan Surat",
          sortable: false
        },
        document_number: {
          label: "Nomor Surat",
          sortable: false
        },
        jenis_surat: {
          label: "Jenis Surat",
          sortable: false
        },
        sifat: {
          label: "Sifat Surat",
          sortable: false
        },
        tanggal_diterima: {
          label: "Tanggal Distribusi",
          sortable: false
        },
        subject: {
          label: "Perihal",
          sortable: false
        },
      },
      
    };
  },
  computed: {
    items() {
      return this.$store.state.statistikPencarianLanjut.items ? this.$store.state.statistikPencarianLanjut.items.items : [];
    },
    filters() {
      return this.$store.state.statistikPencarianLanjut.items ? this.$store.state.statistikPencarianLanjut.items.filters: {};
    },
    state() {
      return this.$store.state.statistikPencarianLanjut;
    },
    forbidUpdate(){
      if(this.$store.state.profile.permissions.document_in){
        return !this.$store.state.profile.permissions.document_in.update;
      }
      return false;
    },
    forbidRead(){
      if(this.$store.state.profile.permissions.document_in){
        return !this.$store.state.profile.permissions.document_in.read;
      }
      return false;
    },
    forbidDel(){
      if(this.$store.state.profile.permissions.document_in){
        return !this.$store.state.profile.permissions.document_in.delete;
      }
      return false;
    }
  },
  mounted() {
    const state = {
      loaded: false,
      items: []
    };
    this.$store.commit("statistikPencarianLanjut/STATE", state);
    this.get(state);
  },
  methods: {
    get(val) {
      this.$store.dispatch("statistikPencarianLanjut/getStatistikPencarianLanjut", val);
    },
    doDetail(val) {
      this.$store.dispatch("statistikPencarianLanjut/onDetail", val);
    },
    doEdit(val) {
      this.$store.dispatch("statistikPencarianLanjut/onEdit", val);
    },
    doDelete(val) {
      this.$store.dispatch("statistikPencarianLanjut/submitDelete", val);
    }
  }
};
</script>
